import React from 'react';

function FetchProgressBar() {


    return (
        <></>
    )

}

export default FetchProgressBar;